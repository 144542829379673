import * as React from "react";
import type { PageProps } from "gatsby";
import ComingSoon from "../components/layout/ComingSoon";
import WithAuth from "../components/user/WithAuth";

const SettingsRoute = (props: PageProps) => {
  return <WithAuth><ComingSoon {...props} children={undefined} /></WithAuth>;
};

export const Head = () => (
  <>
    <title>Runic | My Settings</title>
  </>
)


export default SettingsRoute;
