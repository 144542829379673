import React from "react";
import type { PageProps } from "gatsby";
import BaseLayout from "./BaseLayout";
const { default: IconBlue } =
  require("../../images/popstox-icon-blue.svg") as {
    default: string;
  };

const ComingSoon = (props: PageProps) => {

  return (
    <BaseLayout {...props}>
      <div>
        <div className={"grid h-screen place-items-center"}>
          <div className={"w-auto bg-white rounded-md p-12 m-2 text-center"}>
            <img src={IconBlue} width={100} height={100} className={"inline-block"} />
            <h1 className={"text-2xl text-gray-400"}>feature under active development</h1>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ComingSoon;